import {
  ArrowRightOutlined,
  CopyOutlined,
  EditOutlined,
  FileDoneOutlined,
  FileSyncOutlined,
  IssuesCloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";

export const KCI_STATUS_MAPPING: any = {
  APPROVE: "Approved",
  REJECT: "Not Approved",
  WAIT_APPROVE: "Pending Approval",
  DRAFT: "Draft",
  WAIT_APPROVE_DELETE: "Pending Deletion Approval",
};

export const KCI_STATUS_COLOR_MAPPING: any = {
  APPROVE: "#26CC73",
  REJECT: "#EB5757",
  WAIT_APPROVE: "#F7D564",
  DRAFT: "#B1B4B2",
  WAIT_APPROVE_DELETE: "#26CC73",
};

export const KCI_EDIT_STATUS: any = {
  APPROVE: false,
  REJECT: false,
  WAIT_APPROVE: true,
  DRAFT: false,
  WAIT_APPROVE_DELETE: true,
};
export const KCI_HISTORY_STATUS: any = {
  คัดลอก: <CopyOutlined />,
  สร้างรายการใหม่: <PlusOutlined />,
  แก้ไขรายการ: <EditOutlined />,
  ย้ายกลุ่ม: <ArrowRightOutlined />,
  หัวหน้าดำเนินการ: <FileDoneOutlined />,
  ขออนุมัติลบKCI: <FileSyncOutlined />,
  ขอยกเลิกลบKCI: <IssuesCloseOutlined />,
  หัวหน้าได้แก้ไขรายการ: <EditOutlined />,
};
